<template>
  <main>
    <div class="intro-container">
      <div class="intro">
        <div class="intro-flex">
          <div class="me">
            <img src="https://cdn.discordapp.com/avatars/298153126223937538/7ab7be2db35575d601605bab82d343bd.webp?size=80" alt="" style="width:80px;">
            <div class="flame-profile" style="margin-left: 5px">
              <h1>NeverCookFirst#1249</h1>
             <!-- <div class="badge">Developer</div> -->
            </div>
          </div>
          <!-- <div class="player">
            <div class="control">
              <i class="fa-solid fa-backward-step" id="prev"></i>
              <i class="fa-solid fa-stop" id="play"></i>
              <i class="fa-solid fa-forward-step" id="next"></i>
            </div>
          </div> -->
           <div class="links">
            <img src='https://cdn.discordapp.com/emojis/1011512419627057162.webp?size=60' alt="" class="link"/>
            <img src='https://cdn.discordapp.com/emojis/1011512402891771914.webp?size=60&quality=lossless' alt="" class="link"/>
            <img src='https://media.discordapp.net/attachments/952983464339189852/1041483274037497947/160b3a47acceb3af.png?width=60&height=60' alt="" class="link"/>
            <img src='https://cdn.discordapp.com/emojis/1011512416623931452.webp?size=60' alt="" class="link"/>
            <img src='https://cdn.discordapp.com/emojis/972917028493078559.webp?size=60&quality=lossless' alt="" class="link"/>
            <img src='https://media.discordapp.net/attachments/952983464339189852/1041483734374953010/72b09178fb2ab4bf.png?width=60&height=60' alt="" class="link"/>
            
          </div>
        </div>
        <div class="big-buttons">
          <!-- https://discord.com/channels/@me/246646936713691136 -->
          <a href="https://solo.to/nynxx" class="bigbtn-container">
            Contact Me
          </a>
           <a href="/paint" class="bigbtn-container">
            Paint ^^
          </a>
        </div>
        <div class="about-me">
            <h2 class="aboutme-title">About Me</h2>
            <div class="aboutme-text"><p>Hi! My name is Kirill, I'm Russian Translator with over then 2 years of active translation ・ Account Manager in Discord and the guy who love to take a pictures of something interesting.</p></div>
            <!-- <div class="aboutme-text">Упс...</div> -->
        </div>
        <div class="projects">
          <h2 class="projects-title">Top Projects</h2>
          <div class="projects-container">
            <div class="projects-card valorant">
              <i class="fa-sharp fa-solid fa-star star"></i>
              <a href="https://discord.gg/valorantru">
                <img src="https://cdn.discordapp.com/icons/681925602747351080/6d025a4bffa440988aeb2c6cb2a53360.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/valorantru" class="project-name">VALORANT CIS</a>
              </a>
            </div>
            <div class="projects-card valorant">
                <i class="fa-sharp fa-solid fa-star star"></i>
                <a href="https://discord.gg/nix">
                  <img src="https://cdn.discordapp.com/icons/824603353504808960/a_213b201bdd9fca1bdaccdc89cc83a1b2.gif?size=80" alt="" class="project-icon">
                  <a href="https://discord.gg/nix" class="project-name">NIX STREAM</a>
                </a>
              </div>
            <div class="projects-card npl">
              <i class="fa-sharp fa-solid fa-star star"></i>
              <a href="https://discord.gg/cowbell">
                <img src="https://cdn.discordapp.com/icons/952639736638504960/a_60099fa3b1f60cc479e73c5cfd6ae7c6.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/cowbell" class="project-name">Phonk Community</a>
              </a>
            </div>
            <div class="projects-card hatory">
                <a href="https://discord.gg/kordhell">
                  <img src="https://cdn.discordapp.com/icons/1088361475678154762/f7c508d2239e02f5e8ec01a117f857f9.webp?size=80" alt="" class="project-icon">
                  <a href="https://discord.gg/kordhell" class="project-name">KORDHELL</a>
                </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/slavamarlow">
                <img src="https://cdn.discordapp.com/icons/983705153540620328/ec52d572f518380c7e963fc7584e7593.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/slavamarlow" class="project-name">Slava Marlow</a>
              </a>
             </div>
            <div class="projects-card">
              <a href="https://discord.gg/pixie">
                <img src="https://cdn.discordapp.com/icons/826446674595151872/a_ead6620c6a9597bbde4bd51b0439959e.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/pixie" class="project-name">Pixie</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/dvrst">
                <img src="https://cdn.discordapp.com/icons/780117353668411403/a_6985dc9bcf56fc3ade07c15fc8207a3e.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/dvrst" class="project-name">DVRST</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/jesusavgn">
                <img src="https://cdn.discordapp.com/icons/986784113040191589/99c2bb79cb4fa7c95144279dbb706862.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/jesusavgn" class="project-name">JesusAVGN</a>
              </a>
            </div>
            <div class="projects-card">
              <a href="https://discord.gg/robzi">
                <img src="https://cdn.discordapp.com/icons/427115188085456899/a_70fcb586e210e69d055e03c602b31280.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/robzi" class="project-name">Robzi</a>
              </a>
            </div>
            <!-- <div class="projects-card">
              <a href="https://discord.gg/hatoryds">
                <img src="https://cdn.discordapp.com/icons/894663985650081864/a_74c70f4c5f6e833b7b072c86f98fe010.gif?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/hatory" class="project-name">Hatory</a>
              </a>
            </div> -->
            <div class="projects-card">
              <a href="https://discord.gg/nya">
                <img src="https://cdn.discordapp.com/icons/525360269023641631/a_4ec76285992163ba0296e8ab2f9e2548.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/nya" class="project-name">Neko.land</a>
              </a>
            </div>
            <div class="projects-card rc">
              <a href="https://discord.gg/rc">
                <img src="https://cdn.discordapp.com/icons/252378568665989121/231ba6e53ca4b4e19239a0904c581dd4.webp?size=80" alt="" class="project-icon">
                <a href="https://discord.gg/rc" class="project-name">RC</a>
              </a>
            </div>
          </div>
        </div>

        <!-- <div class="projects">
          <h2 class="">Fame/Media</h2>
          <div class="projects-container">
            <div class="projects-card">
              <img src="https://cdn.discordapp.com/icons/681925602747351080/bb0e3997f43e461e9c3b1ad4a551bc4e.webp?size=80" alt="" class="project-icon">
              <a href="https://discord.gg/valorantru" class="project-name">VALORANT СНГ</a>
            </div>
          </div>
        </div> -->

        <div style="height: 70px;"></div>

        <div class="projects">
            <h2 class="projects-title">Previously Projects</h2>
            <div class="projects-container">
              <div class="projects-card fantasy">
                  <a href="https://discord.gg/toweroffantasy">
                    <img src="https://media.discordapp.net/attachments/843093572635197461/1092546130996379808/ezgif-4-84e09365d1.png?width=80&height=80" alt="" class="project-icon">
                    <a href="https://discord.gg/toweroffantasy" class="valorant-name">Tower Of Fantasy</a>
                  </a>
              </div>
              <div class="projects-card hatory">
                  <a href="https://discord.gg/89squad">
                    <img src="https://cdn.discordapp.com/icons/259124796971941890/a_ad75bc339dfb8a5bcfbf924911a93acc.gif?size=80" alt="" class="project-icon">
                    <a href="https://discord.gg/89squad" class="project-name">89-SQUAD</a>
                  </a>
              </div>
              <div class="projects-card">
                    <a href="https://discord.gg/deepins">
                      <img src="https://cdn.discordapp.com/attachments/843093572635197461/1092544758871756820/ezgif-4-e3eb05f905.png?size=80" alt="" class="project-icon">
                      <a href="https://discord.gg/deepins" class="valorant-name">Deep🤢Dis</a>
                    </a>
              </div>
              <div class="projects-card">
                      <a href="https://discord.gg/zxcursed">
                        <img src="https://cdn.discordapp.com/icons/796861735016726539/a_41a1132e653188f56ffdc54dbd73607a.gif?size=80" alt="" class="project-icon">
                        <a href="https://discord.gg/zxcursed" class="valorant-name">zxcursed</a>
                      </a>
                </div>
            </div>
        </div>

        <div style="height: 70px;"></div>

        <div class="partners">
          <h2 class="pretners-title">Partners</h2>
          <div class="partners-container">
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/933632681592356894/1059796988020928573/ezgif.com-gif-maker.png" alt="" class="project-icon">
              <a href="https://www.toweroffantasy-global.com/" class="project-name">Hotta Studio</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/1014883899723223111/1014884063892488213/riot_games.png?height=80&width=80" alt="" class="project-icon">
              <a href="https://www.riotgames.com/en" class="project-name">Riot Games</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/843093572635197461/1092545251312414850/ezgif-4-d0c68572c0.png?width=64&height=64" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/0dgJbQ0bKPyUXco8hEXN7X?si=520bb09d5dfc404b" class="project-name">Øneheart</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/586651724299894785/1044351357672493076/image.png?width=80&height=80" alt="" class="project-icon">
              <a href="https://twitch.tv/deepins02" class="project-name">deepins02</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/1014883899723223111/1014887206869016606/nevermore.jpg?height=80&width=80" alt="" class="project-icon">
              <a href="https://www.twitch.tv/razedoto" class="project-name">Shadowraze</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/1014883899723223111/1014887570494193734/zxcursed.png?height=80&width=80" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/53fwP60M1QNPDiXALVMeOs?si=SWGCpXA2Sc6Fb7k_RSuYRA" class="project-name">zxcursed</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/952983464339189852/1041075148142428160/unknown.png?height=80&width=80" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/0XFgyr4jwM0MGeZZW0VzA5?si=iAzrHT4BSLWF0xp3qT_hiQ" class="project-name">DVRST</a>
            </div>
            <div class="partners-card">
              <img src="https://cdn.discordapp.com/attachments/952983464339189852/1041076520912949349/unknown-2-2.png" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/3zgn9mSaWsqdpcKo94Z5oi?si=QWfmKIZmTaSRKlePILA3iQ" class="project-name">Rizza</a>
            </div>
             <div class="partners-card">
              <img src="https://cdn.discordapp.com/attachments/952983464339189852/1041076659295617064/unknown-3-2.png" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/2IGZnq5Ot1NquCpks3zO4o?si=C3BteptQRrmVyeD02wkjDw" class="project-name">Sqwore</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/1014883899723223111/1014884428595609660/jesusavgn.jpg?height=80&width=80" alt="" class="project-icon">
              <a href="https://www.twitch.tv/jesusavgn" class="project-name">JesusAVGN</a>
            </div>
            <div class="partners-card">
              <img src="https://cdn.discordapp.com/attachments/933632681592356894/1065020039713005568/ezgif-2-bae9760fba.png" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/55jryyk7RhvMbrvoF0ndBh?si=yvpcJ7uFRVqPeQ21p4xxsA" class="project-name">Slava Marlow</a>
            </div>
            <div class="partners-card">
              <img src="https://media.discordapp.net/attachments/952983464339189852/1041077956254113903/unknown.png?width=80&height=80" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/496nklFjflGjJOhhfhH2Nc?si=yCzS7RmxSearRVry8uTTQw" class="project-name">17 SEVENTEEN</a>
            </div>

            <div class="partners-card">
              <img src="https://cdn.discordapp.com/attachments/933632681592356894/1065019903712698450/image.png" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/5hKGLu4Ik88FzWcTPhWNTN?si=7CFrxdcIQKKT7w2batIbSA" class="project-name">INTERWORLD</a>
            </div>
            <div class="partners-card">
              <img src="https://cdn.discordapp.com/attachments/933632681592356894/1065019922838716416/image.png" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/5ZzO786ViqgegCIoa28OPx?si=MFJJg4JwTb2yzTDQFpBylw" class="project-name">SHADXWBXRN</a>
            </div>
            <div class="partners-card">
              <img src="https://cdn.discordapp.com/attachments/933632681592356894/1065019971786248292/image.png" alt="" class="project-icon">
              <a href="https://open.spotify.com/artist/5UJkhYfIETGF5PMO0DT3aj?si=LGFILzMVQAaOH0ZCh18NqQ" class="project-name">KXNVRA</a>
            </div>
          </div>
        </div>

          <!-- <div class="projects">
          <h2 class="friends-title">Friends</h2>
          <div class="projects-container">
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/354261484395560961/a_59aeef3df7e81cc4fea15d06034a3fa2.gif?size=80" alt="" class="project-icon">
              <a class="project-name">Jerry Smith#6666</a>
            </div>
            <div class="team-card">
              <img src="https://media.discordapp.net/attachments/1014883899723223111/1041078368768106616/avatar4.gif?width=80&height=80" alt="" class="project-icon">
              <a href="https://animeflame.store" class="project-name">Anime Flame#6666</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/244057609622126592/52a6b6132e29e09b9b81bdc42761f105.webp?size=80" alt="" class="project-icon">
              <a class="project-name">Shlomi#0001</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/294508219718696960/f5f9159e026fc2ed911fc304deaa8891.webp?size=80" alt="" class="project-icon">
              <a class="project-name">yokally#1993</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/496569904527441921/629af6b5e5aededad7f86eeee1872511.webp?size=80" alt="" class="project-icon">
              <a class="project-name">Naneynonn#0101</a>
            </div>
            <div class="team-card">
              <img src="https://cdn.discordapp.com/avatars/287934700230541312/a_3cb28e9362723717cd83f38d281d4cdf.gif?size=80" alt=""
                class="project-icon">
              <a href="https://discord.com/users/993644554689454122" class="project-name">Andrew Dark#9249</a>
            </div>
          </div>
        </div> -->
        
        <div class="stats">
          <h2 class="stats-title">Stats</h2>
          <div class="stats-container" id="counter">
            <div class="stats-block">
              <div class="stats-number" data-num="1100000">1100000</div>
              <div class="stats-value">Users</div>
            </div>
            <div class="stats-block">
              <div class="stats-number">888</div>
              <div class="stats-value">Unsleeped Nights</div>
            </div>
            <div class="stats-block">
              <div class="stats-number" data-num="600">6</div>
              <div class="stats-value">Years Of Experience</div>
            </div>
          </div>
        </div>
        <div class="made">Made by <span class="badge">Jerry Smith && Anime Flame</span></div>
      </div>
      
    </div>
  </main>
</template>

<script>
require('../js/main.js');
//require('../js/player.js')
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 @import '../css/main';
</style>